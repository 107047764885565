<template>
  <div>
    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      id="modal__emr__upload"
    >
      <div
        class="modal-dialog"
        role="document"
      >
        <div class="modal-content">
          <div class="d-flex justify-content-between p-3">
            <div class="fs-24 fw-700 txt-grey-900 text-center w-100">
              <span>Upload Phiếu</span>
            </div>
            <!-- <button
              type="button"
              class="close bg-white border-0"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                aria-hidden="true"
                class="fs-30 fw-500"
              >&times;</span>
            </button> -->
          </div>
          <div class="modal-body">
            <div class="row mt-2">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-12 mb-3">
                    <div class="form-group">
                      <label class="robo-16-500 required">Tên Phiếu</label>
                      <input
                        type="text"
                        class="form-control mt-2 robo-16-500"
                        placeholder="Nhập tên phiếu"
                        v-model="form.name"
                      >
                    </div>
                  </div>
                  <div class="col-sm-12 mb-3">
                    <div class="form-group">
                      <label class="robo-16-500 required">Phiếu ngày</label>
                      <el-date-picker
                        type="datetime"
                        placeholder="Chọn ngày giờ"
                        v-model="form.date"
                      ></el-date-picker>
                    </div>
                  </div>
                  <div class="col-sm-12 d-flex justify-content-between align-items-center flex-wrap">
                    <p class="mb-0">
                      {{file ? file.name : 'Chưa chọn file...'}}
                    </p>
                    <p
                      v-if="file"
                      style="border-bottom: solid 1.5px #20419B;"
                      @click="onShowPickFile()"
                      class="cursor-pointer txt-pri d-flex mb-0"
                    >Thay đổi</p>
                    <button
                      v-if="!file"
                      class="btn radius-0 d-flex align-items-center bg-pri bd-pri text-white robo-16-500 mr-2 text-right"
                      @click="onShowPickFile()"
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.039 5.13579L8.1 3.06528V11.6984C8.1 11.9372 8.19482 12.1662 8.3636 12.335C8.53239 12.5038 8.7613 12.5987 9 12.5987C9.2387 12.5987 9.46761 12.5038 9.6364 12.335C9.80518 12.1662 9.9 11.9372 9.9 11.6984V3.06528L11.961 5.13579C12.0447 5.22017 12.1442 5.28714 12.2539 5.33284C12.3636 5.37855 12.4812 5.40208 12.6 5.40208C12.7188 5.40208 12.8364 5.37855 12.9461 5.33284C13.0558 5.28714 13.1553 5.22017 13.239 5.13579C13.3234 5.0521 13.3903 4.95254 13.436 4.84284C13.4817 4.73314 13.5052 4.61547 13.5052 4.49663C13.5052 4.37779 13.4817 4.26013 13.436 4.15043C13.3903 4.04073 13.3234 3.94116 13.239 3.85747L9.639 0.256576C9.55341 0.174619 9.45248 0.110375 9.342 0.067529C9.12288 -0.0225097 8.87712 -0.0225097 8.658 0.067529C8.54752 0.110375 8.44659 0.174619 8.361 0.256576L4.761 3.85747C4.67708 3.94141 4.61052 4.04106 4.56511 4.15072C4.51969 4.26039 4.49632 4.37793 4.49632 4.49663C4.49632 4.61534 4.51969 4.73288 4.56511 4.84254C4.61052 4.95221 4.67708 5.05186 4.761 5.13579C4.84491 5.21973 4.94454 5.28631 5.05418 5.33173C5.16382 5.37716 5.28133 5.40054 5.4 5.40054C5.51867 5.40054 5.63618 5.37716 5.74582 5.33173C5.85546 5.28631 5.95509 5.21973 6.039 5.13579ZM17.1 8.99776C16.8613 8.99776 16.6324 9.0926 16.4636 9.26142C16.2948 9.43025 16.2 9.65922 16.2 9.89798V15.2993C16.2 15.5381 16.1052 15.7671 15.9364 15.9359C15.7676 16.1047 15.5387 16.1996 15.3 16.1996H2.7C2.4613 16.1996 2.23239 16.1047 2.0636 15.9359C1.89482 15.7671 1.8 15.5381 1.8 15.2993V9.89798C1.8 9.65922 1.70518 9.43025 1.5364 9.26142C1.36761 9.0926 1.13869 8.99776 0.9 8.99776C0.661305 8.99776 0.432387 9.0926 0.263604 9.26142C0.0948211 9.43025 0 9.65922 0 9.89798V15.2993C0 16.0156 0.284464 16.7025 0.790812 17.209C1.29716 17.7155 1.98392 18 2.7 18H15.3C16.0161 18 16.7028 17.7155 17.2092 17.209C17.7155 16.7025 18 16.0156 18 15.2993V9.89798C18 9.65922 17.9052 9.43025 17.7364 9.26142C17.5676 9.0926 17.3387 8.99776 17.1 8.99776Z"
                          fill="white"
                        />
                      </svg>
                      Chọn file
                    </button>
                    <input
                      id="file-upload"
                      class="d-none"
                      name='file-upload'
                      type="file"
                      @change="onSelectFile"
                      accept="jpeg, .png, .jpg, .gif, .svg, .doc, .docx, .pdf, .xls, .xlsx, .xlsm, .ppt, .pptx, .heic"
                    />
                  </div>
                  <div class="col-12">
                    <small class="text-red">{{err_file}}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              id="closeModal"
              type="button"
              class="btn btn-muted"
              data-dismiss="modal"
              @click="closeModal"
            >Huỷ</button>
            <button
              :disabled="is_loading"
              type="button"
              class="btn bg-pri bd-pri text-white"
              @click="onSubmit()"
            >
              Lưu
              <span
                class="spinner-border spinner-border-sm mr-2"
                role="status"
                v-if="is_loading"
              ></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  name: 'ModalEMRUpload',
  data () {
    return {
      content: '',
      err_file: '',
      exts_list: ['jpeg', 'png', 'jpg', 'gif', 'svg', 'doc', 'docx', 'pdf', 'xls', 'xlsx', 'xlsm', 'ppt', 'pptx', 'heic'],
      is_loading: false,
      form: {
        medical_record_id: null,
        name: 'Phiếu sơ kết ' + window.moment().format('DD-MM-YYYY'),
        date: moment().format('YYYY-MM-DD HH:mm'),
        format: 2,
        pdf: null
      },
      file: null,
      moment
    }
  },
  mounted () { },
  methods: {
    onSubmit () {
      let self = this
      if (self.is_loading) return
      if (!self.file) {
        self.err_file = 'Vui lòng chọn file'
        return false
      } else if (!self.validateFile(self.file)) {
        self.err_file = 'Vui lòng chọn file có định dạng jpeg,png,jpg,gif,svg,doc,docx,pdf,xls,xlsx,heic'
        return false
      } else {
        this.form.medical_record_id = this.$route.params.id
        this.form.pdf = this.file
        this.form.date = moment(this.form.date).format('YYYY-MM-DD HH:mm')
        self.$emit('onSubmit', this.form)
        this.closeModal()
        window.$('button#closeModal').trigger('click')
      }
    },
    validateFile (file) {
      let ext = file.name.split('.').pop()
      return !!this.exts_list.find(el => el === ext.toLowerCase())
    },
    onSelectFile (event) {
      this.file = event.target.files[0]
    },
    onShowPickFile () {
      this.err_file = ''
      window.$('input[id="file-upload"]').trigger('click')
    },
    closeModal () {
      this.file = null
      this.form = {
        medical_record_id: null,
        name: 'Phiếu sơ kết ' + window.moment().format('DD-MM-YYYY'),
        date: moment().format('YYYY-MM-DD HH:mm'),
        format: 2,
        pdf: null
      }
    }
  }
}
</script>