<template>
  <div class="modal fade" id="modal__chuyen_bien_ban_den_badt">
    <div class="modal-dialog modal-lg shadow" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Chuyển đến bệnh án điện tử</h5>
        </div>
        <div class="modal-body txt-grey-900">
          <div class="row">
            <div class="col-md-6">
              <label class="fs-14 fw-500 required">Bệnh nhân</label>
              <el-select
                class="w-full"
                v-model="selected_patient_id"
                filterable
                placeholder="Chọn bệnh nhân"
                @change="setName"
                remote
                :remote-method="getPatientsWS"
                :loading="loading"
                @focus="getPatientsWS()"
              >
                <el-option
                  v-for="item in listPatient"
                  :key="item.id"
                  :label="item.person && item.person.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>

            <!-- <div class="col-md-6 flex items-end justify-end">
              <ButtonHoDo
                title="Thêm Bệnh nhân"
                @click="showModalAddPatient(true)"
                class-name="bg-blue-700 text-white rounded-lg h-10 px-3 py-2 mx-3"
              />
            </div>-->

            <div class="col-md-6">
              <div class="d-flex align-items-center">
                <label class="fs-14 fw-500 required">Bệnh án</label>
                <span class="fs-10 text-danger ml-2">
                  {{
                  !selected_patient_id ? "Cần chọn bệnh nhân trước" : ""
                  }}
                </span>
              </div>
              <el-select
                class="w-full"
                v-model="medical_record"
                filterable
                placeholder="Chọn bệnh án"
                :disabled="!selected_patient_id"
                :remote-method="getWorkspaceMRByPatientId"
                :loading="loading"
                @focus="getWorkspaceMRByPatientId()"
              >
                <el-option
                  v-for="item in medical_records"
                  :key="item.id"
                  :label="item.medical_record_name"
                  :value="item"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="mt-6 row">
            <div class="col-md-12">
              <div class="fs-14 fw-500">&nbsp;</div>
              <label
                v-if="concluding_data"
                for="send_c"
                class="fs-16 mr-1 d-block mb-1"
                :class="
                  concluding_data.medical_record_id
                    ? 'txt-grey-600'
                    : 'cursor-pointer txt-grey-900'
                "
              >
                <input
                  type="checkbox"
                  name
                  id="send_c"
                  readonly
                  :disabled="concluding_data.medical_record_id"
                  v-model="send_concluding"
                />
                Biên bản hội chẩn
                {{
                concluding_data && concluding_data.medical_record_id
                ? "(Đã chuyển)"
                : ""
                }}
              </label>
              <label
                v-if="operation_data"
                for="send_o"
                class="fs-16 mr-1 d-block"
                :class="
                  !operation_data || operation_data.medical_record_id
                    ? 'txt-grey-600'
                    : 'cursor-pointer txt-grey-900'"
              >
                <input
                  type="checkbox"
                  name
                  id="send_o"
                  readonly
                  :disabled="
                    !operation_data || operation_data.medical_record_id
                  "
                  v-model="send_operation"
                />
                Tóm tắt thông qua mổ
                {{
                operation_data && operation_data.medical_record_id
                ? "(Đã chuyển)"
                : ""
                }}
              </label>
            </div>
          </div>

          <div class="mt-6 text-sm font-medium flex justify-end">
            <button
              @click="onSend"
              :disabled="
                !medical_record ||
                (!send_concluding && !send_operation) ||
                (concluding_data &&
                  concluding_data.medical_record_id &&
                  operation_data &&
                  operation_data.medical_record_id)"
              class="flex items-center justify-center border-0 focus:outline-none mx-3 bg-blue-700 text-white rounded-md h-9 px-3 py-2"
            >Chuyển</button>
            <button
              @click="showModalThis(false)"
              class="flex items-center justify-center border-0 focus:outline-none bg-blue-50 text-primary rounded-md h-9 px-3 py-2"
            >
              <div>Hủy</div>
            </button>
          </div>
        </div>
      </div>
    </div>

    <ModalAddPatient @complete="completeAddPatient($event)" />
  </div>
</template>
<script>
import { ButtonHoDo } from '@/components/Button'
import ModalAddPatient from '../Workspace/ModalAddPatient.vue'
export default {
  name: 'ModalAddBenhAn',
  components: { ModalAddPatient, ButtonHoDo },
  props: ['concluding_data', 'operation_data'],
  data () {
    return {
      listPatient: [],
      selected_patient_id: null,
      medical_record: null,
      patient: {},
      options: [],
      send_concluding: false,
      send_operation: false,
      medical_records: {},
      submiting: false,
      loading: false
    }
  },
  created () {},
  mounted () {
    window.$(document).ready(function () {
      window
        .$('#modal__chuyen_bien_ban_den_badt')
        .on('hidden.bs.modal', function (e) {
          // self.error = false
        })
    })
    this.getPatientsWS()
  },
  watch: {
    concluding_data (d) {
      this.send_concluding = !!d?.medical_record_id
    },
    operation_data (d) {
      this.send_operation = !!d?.medical_record_id
    }
  },
  methods: {
    showModalAddPatient (show) {
      window.$('#modal__add_patient').modal(show ? 'show' : 'hide')
    },
    setName (value) {
      this.medical_record = null
      if (value) {
        this.getWorkspaceMRByPatientId()
      }
    },
    showModalThis (show) {
      window
        .$('#modal__chuyen_bien_ban_den_badt')
        .modal(show ? 'show' : 'hide')
      if (!show) {
        this.selected_patient_id = null
        this.medical_record = null
        this.send_concluding = false
        this.send_operation = false
      }
    },
    onSend () {
      if (!this.medical_record) {
        alert('Vui lòng chọn bệnh án!')
        return
      }
      if (!this.send_concluding && !this.send_operation) {
        alert('Vui lòng chọn biên bản cần chuyển!')
        return
      }
      if (
        (this.concluding_data?.medical_record_id &&
          this.operation_data?.medical_record_id) ||
        (!this.concluding_data && this.operation_data?.medical_record_id) ||
        (!this.operation_data && this.concluding_data?.medical_record_id)
      ) {
        alert('Tất cả biên bản đã được chuyển!')
        return
      }
      this.onSubmit()
    },
    completeAddPatient (data) {
      this.patient = data
      this.getPatientsWS(null, this.patient?.id)
    },
    async getPatientsWS (search, currId = null) {
      if (!this.$globalClinic || !this.$globalClinic.id) return
      let params = {
        limit: 50,
        sort: 'created_at',
        sort_by: 'desc'
      }
      if (search) {
        params.search = search
      }
      this.loading = true
      let r = await this.$rf
        .getRequest('DoctorRequest')
        .getPatientsWS(this.$globalClinic.id, params)
        .then(r => {
          this.listPatient = r.data?.data
          if (currId) {
            this.selected_patient_id = currId
          }
          return r
        })
        .catch(e => {
          console.log(e)
          return false
        })
        .finally(() => {
          this.loading = false
        })
      return r
    },
    async getWorkspaceMRByPatientId (search = null) {
      if (
        !this.$globalClinic ||
        !this.$globalClinic.id ||
        !this.selected_patient_id
      ) { return }
      const params = {
        page: 1,
        limit: 500
      }
      if (search) {
        params.search = search
      }
      this.loading = true
      let r = await this.$rf
        .getRequest('DoctorRequest')
        .getWorkspaceMRByPatientId(
          this.$globalClinic.id,
          this.selected_patient_id,
          params
        )
        .then(r => {
          this.medical_records = r.data?.data
          return r
        })
        .catch(e => {
          console.log(e)
          return false
        })
        .finally(() => {
          this.loading = false
        })
      return r
    },
    async onSubmit () {
      try {
        this.submiting = true
        await this.sendOperation()
        await this.sendConcluding()
        this.$emit('refresh')
        this.showModalThis()

        this.$toast.open({
          message: 'Chuyển hoàn tất',
          type: 'success'
        })
      } catch (error) {
      } finally {
        this.submiting = false
      }
    },
    async sendOperation () {
      if (!this.operation_data || !this.medical_record) return
      let r = await this.$rf
        .getRequest('DoctorRequest')
        .sendMdtOperationToMr(this.medical_record?.id, this.operation_data?.id)
      return r
    },
    async sendConcluding () {
      if (!this.concluding_data || !this.medical_record) return
      let r = await this.$rf
        .getRequest('DoctorRequest')
        .sendMdtConcludingToMr(
          this.medical_record?.id,
          this.concluding_data?.id
        )
      return r
    }
  }
}
</script>
<style scoped>
</style>