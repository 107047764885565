<template>
  <div>
    <div class="modal fade" :id="`modalSaveDocTemplate-${docType}`">
      <div
        v-loading="loading"
        class="modal-dialog modal-lg shadow"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Tạo mới mẫu phiếu</h5>
          </div>
          <div class="modal-body txt-grey-900">
            <div class="row">
              <div class="col-md-6">
                <label class="fs-14 fw-500 required">Chọn mẫu bệnh án</label>

                <el-select
                  clearable
                  filterable
                  allow-create
                  v-model="form.emr_template_id"
                  class="w-full"
                  placeholder="Chọn mẫu bệnh án"
                  @change="handleChangeEmrTemplate"
                  remote
                  :remote-method="handleDebounce"
                  :loading="loading"
                >
                  <el-option
                    v-for="item in emrTemplateList"
                    :key="item.id + 'md'"
                    :label="handleGetTemLabel(item)"
                    :value="item.id"
                  />
                </el-select>
              </div>
              <div class="col-md-6">
                <div>
                  <label class="fs-14 fw-500 required">Tên mẫu phiếu</label>
                  <input
                    v-model="form.doc_name"
                    class="form--input w-full h-10"
                  />
                </div>
              </div>
            </div>
            <div class="mt-6 row">
              <div class="col-md-6">
                <label class="fs-14 fw-500">Khoa</label>
                <label
                  class="form--input fw-500 h-10 cursor-not-allowed flex items-center"
                  >{{ $globalClinic ? $globalClinic.name : "" }}</label
                >
              </div>
              <div class="col-md-6">
                <label class="fs-14 fw-500">Người tạo</label>
                <label
                  class="form--input fw-500 h-10 cursor-not-allowed flex items-center"
                  >{{ form.created_by }}</label
                >
              </div>
              <div class="col-md-6 mt-6">
                <label class="fs-14 fw-500">Ngày tạo</label>
                <label
                  class="form--input fw-500 h-10 cursor-not-allowed flex items-center"
                  >{{ getDate(form.created_at) }}</label
                >
              </div>
              <div class="col-md-6 mt-6">
                <div>Chọn loại</div>
                <el-select
                  :disabled="!isAdmin"
                  class="w-full"
                  filterable
                  v-model="form.is_public"
                >
                  <el-option label="Dùng cho workspace" :value="1" />
                  <el-option label="Dùng cho cá nhân" :value="0" />
                </el-select>
              </div>
            </div>
            <div class="mt-4 text-sm font-medium flex justify-end">
              <button
                @click="handleSubmit"
                class="flex items-center justify-center border-0 focus:outline-none mx-3 bg-blue-700 text-white rounded-md h-9 px-3 py-2"
              >
                <div>Tạo mới</div>
              </button>
              <button
                @click="handleCloseModal"
                class="flex items-center justify-center border-0 focus:outline-none bg-blue-50 text-primary rounded-md h-9 px-3 py-2"
              >
                <div>Hủy</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    <script>
import { debounce } from 'lodash'

const DEFAULT_FORM = {
  created_by: '',
  created_at: window.moment().format('YYYY-MM-DD'),
  is_public: 0,
  emr_template_id: null,
  doc_name: ''
}

export default {
  name: 'ModalAddEmrTemplate',
  props: {
    docContent: Object,
    docType: String
  },
  data () {
    return {
      form: {
        ...DEFAULT_FORM
      },
      loading: false,
      emrTemplateList: []
    }
  },
  computed: {
    isAdmin () {
      const isTrue =
        this.$globalClinic &&
        this.$user &&
        this.$user.doctor &&
        this.$globalClinic.doctor_id === this.$user.doctor.id

      if (!isTrue) this.form.is_public = 0

      return isTrue
    }
  },
  mounted () {
    if (this.$user) {
      this.form.created_by = this.$user.name
    }
  },
  methods: {
    showModalSaveDocTemplate (show) {
      if (show) {
        this.getEmrTemplateList()
      }

      window
        .$(`#modalSaveDocTemplate-${this.docType}`)
        .modal(show ? 'show' : 'hide')
    },
    handleCloseModal () {
      this.showModalSaveDocTemplate(false)
      this.form = { ...DEFAULT_FORM, created_by: this.$user.name }
    },
    getDate (date) {
      if (!date) return ''
      return window.moment(date).format('DD/MM/YYYY')
    },
    async handleSubmit () {
      if (
        this.form.emr_template_id &&
        typeof this.form.emr_template_id === 'string'
      ) {
        const response = await this.handleCreateEmrTemplate()

        this.handleCreateDocTemplate(response?.id)
        return
      }

      this.handleCreateDocTemplate()
    },
    async handleCreateEmrTemplate () {
      if (!this.$globalClinic?.id) return

      if (!this.form.emr_template_id) {
        this.$toast.open({
          message: 'Trường tên mẫu bệnh án không được để thiếu',
          type: 'warning'
        })

        return
      }

      try {
        const params = {
          name: this.form.emr_template_id,
          workspace_id: this.$globalClinic.id,
          is_public: this.form.is_public
        }
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .postCreateEmrTemplate(params)

        const responseData = response.data?.data || {}
        this.form.emr_template_id = responseData?.id
        return responseData
      } catch (error) {
        console.log(error)
        this.$toast.open({
          message: 'Có lỗi trong quá trình tạo',
          type: 'error'
        })
      }
    },
    async getEmrTemplateList (search) {
      if (!this.$globalClinic && !this.$globalClinic.id) return
      let self = this
      const params = {
        workspace_id: this.$globalClinic.id,
        limit: 10000,
        sort_by: 'desc'
      }
      if (search) {
        params.keyword = search
      }

      const r = await this.$rf
        .getRequest('DoctorRequest')
        .getListEmrTemplate(params)
        .then((r) => {
          const responseData = r.data?.data?.data || []
          if (this.isAdmin) {
            self.emrTemplateList = responseData
          } else {
            self.emrTemplateList =
              responseData.filter((item) => item?.is_public === 0) || []
          }
          return r
        })
        .catch((e) => {
          console.log(e)

          return false
        })
      return r
    },
    handleChangeEmrTemplate (emrTemSelected) {
      if (!emrTemSelected) return

      if (typeof emrTemSelected === 'number') {
        const emrInfo = this.emrTemplateList.find(
          (item) => item?.id === emrTemSelected
        )
        this.form.created_by = emrInfo?.author?.name
        this.form.created_at = emrInfo?.created_at
        this.form.is_public = emrInfo?.is_public
      }
    },
    async handleCreateDocTemplate (id) {
      if (!this.docContent || !this.docType || !this.$globalClinic?.id) return

      if (!this.form.doc_name || (!this.form.emr_template_id && !id)) {
        this.$toast.open({
          message: 'Bạn cần nhập đầy đủ thông tin các trường',
          type: 'warning'
        })

        return
      }

      try {
        const params = {
          name: this.form?.doc_name,
          workspace_id: this.$globalClinic.id,
          emr_template_id: this.form.emr_template_id || id,
          content: JSON.stringify(this.docContent),
          type: Number(this.docType)
        }
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .postCreateDocTemplate(params)

        this.form = { ...DEFAULT_FORM, created_by: this.$user.name }
        this.$toast.open({
          message: 'Tạo thành công',
          type: 'success'
        })
        this.showModalSaveDocTemplate(false)
        console.log(response)
        // const responseData = response.data?.data || {}
        // this.$router.replace({
        //   name: 'EmrTemplateDetail',
        //   params: {
        //     id: responseData?.emr_template_id
        //   },
        //   query: {
        //     vId: responseData?.id,
        //     type: 'view',
        //     tab: this.docType
        //   }
        // })
      } catch (error) {
        console.log(error)
      }
    },
    handleGetTemLabel (data) {
      if (data?.emr_temp_name?.name) {
        return `${data.name} - ${data.emr_temp_name.name}`
      }

      return data.name
    },
    handleDebounce: debounce(function () {
      this.getEmrTemplateList()
    }, 1000)
  }
}
</script>
    <style scoped></style>
    